<template>
  <div v-if="!transactionWaintingResult" class="dashboard">
    <div class="transactions-options">
      <h1>{{ $t('commons.dashboard') }}</h1>

      <div class="orders-searchbar">
        <input v-model="search" type="text" :placeholder="$t('orders.search')" />
        <button>
          <img src="@/assets/svg/v2/search.svg" alt="Search icon" />
        </button>
      </div>
    </div>

    <div v-if="returnFilterTransactions.length > 0" class="transactions">
      <el-table :data="returnFilterTransactions" stripe>
        <el-table-column
          prop="transaction"
          :label="$t('transactions.direction')"
          width="120"
          sortable
        >
          <template v-slot="{ row }">
            <button
              v-if="row.type === 0 || row.ups_tracking"
              class="live-order-list-buy"
            >
              <img
                alt="Buy order"
                height="16"
                src="@/assets/svg/v2/products/buyOrder.svg"
                width="16"
              />
              <p>{{ $t('search.buy') }}</p>
            </button>

            <button v-else-if="row.type === 1" class="live-order-list-sell">
              <img
                alt="Sell order"
                height="16"
                src="@/assets/svg/v2/products/sellOrder.svg"
                width="16"
              />
              <p>{{ $t('search.sell') }}</p>
            </button>

            <button v-else-if="row.type === 2" class="live-order-list-giver">
              <img
                alt="Sell order"
                height="16"
                src="@/assets/svg/v2/products/swap.svg"
                width="16"
              />
              <p>Giver</p>
            </button>

            <button v-else-if="row.type === 3" class="live-order-list-taker">
              <img
                alt="Sell order"
                height="16"
                src="@/assets/svg/v2/products/swap.svg"
                width="16"
              />
              <p>Taker</p>
            </button>

            <button v-else-if="row.type === 4" class="live-order-list-sell">
              <img
                alt="Swap taker order"
                height="16"
                src="@/assets/svg/v2/products/blocktrade.svg"
                style="background: #2667ff !important"
                width="16"
              />
              <p>BlockTrade</p>
            </button>
          </template>
        </el-table-column>

        <el-table-column
          prop="ticker_maitre"
          label="Ticker"
          width="150"
          sortable
        >
          <template v-slot="{ row }">
            <button class="ticker-maitre" v-if="row.infos">
              {{ row.infos[0].code }}
            </button>
          </template>
        </el-table-column>

        <el-table-column
          prop="title"
          :label="$t('search.name')"
          min-width="190"
          sortable
        >
          <template v-slot="{ row }">
            <button class="title">{{ returnName(row) }}</button>
          </template>
        </el-table-column>

        <el-table-column
          prop="date"
          :label="$t('commons.date')"
          width="110"
          sortable
        >
          <template v-slot="{ row }">
            <p class="title">{{ dateConvert(row.created_at) }}</p>
          </template>
        </el-table-column>

        <el-table-column
          prop="price"
          :label="$t('settings.invoices.price')"
          width="100"
          sortable
        >
          <template v-slot="{ row }">
            <p v-if="row.infos" class="price">
              {{ row.infos[0].total_price_gross }}
            </p>
          </template>
        </el-table-column>

        <el-table-column
          prop="status"
          :label="$t('settings.invoices.status')"
          width="100"
          sortable
        >
          <template v-slot="{ row }">
            <button class="status status-paid" v-if="row.status === 'paid'">
              {{ $t('settings.invoices.paid') }}
            </button>
          </template>
        </el-table-column>

        <el-table-column prop="facture" label="" width="160">
          <template v-slot="{ row }">
            <div class="transactions-history-actions">
              <button
                @click="downloadInvoice(row.invoice_uuid)"
                class="download-invoice"
              >
                <el-tooltip
                  class="tooltip"
                  effect="dark"
                  :content="$t('settings.invoices.download')"
                  placement="top"
                >
                  <img
                    src="@/assets/svg/v2/settings/invoiceHistoryActive.svg"
                    alt="Invoice download"
                  />
                </el-tooltip>
              </button>
              <button
                v-if="
                  row.ups_tracking &&
                  !row.delivery_status &&
                  !row.dispute_created_at &&
                  !row.dispute_resolve_by
                "
                class="download-invoice"
                @click="showDetailShippingModal(row)"
              >
                <el-tooltip
                  :content="$t('product_page.orders.shipping_details_see')"
                  class="tooltip"
                  effect="dark"
                  placement="top"
                >
                  <img src="@/assets/svg/v2/products/truck.svg" alt="box" />
                </el-tooltip>
              </button>
              <button
                v-if="
                  row.ups_tracking &&
                  !row.delivery_status &&
                  !row.dispute_created_at &&
                  !row.dispute_resolve_by
                "
                @click="openConfirmShipment(row.order_uuid)"
                class="download-invoice"
              >
                <el-tooltip
                  class="tooltip"
                  effect="dark"
                  :content="$t('product_page.orders.confirm_shipment')"
                  placement="top"
                >
                  <img
                    src="@/assets/svg/v2/orders/package.svg"
                    alt="Shipment OK"
                  />
                </el-tooltip>
              </button>
              <button
                v-if="
                  row.ups_tracking &&
                  !row.delivery_status &&
                  !row.dispute_created_at &&
                  !row.dispute_resolve_by
                "
                @click="openDisputeShipmentOrder(row)"
                class="download-invoice"
              >
                <el-tooltip
                  class="tooltip"
                  effect="dark"
                  :content="$t('commons.report')"
                  placement="top"
                >
                  <img src="@/assets/svg/v2/orders/report.svg" alt="Report" />
                </el-tooltip>
              </button>
              <button
                v-if="row.dispute_created_at && !row.dispute_resolve_by"
                class="download-invoice"
              >
                <el-tooltip
                  class="tooltip"
                  effect="dark"
                  :content="$t('transactions.dispute_in_progress')"
                  placement="top"
                >
                  <div class="report-on"></div>
                </el-tooltip>
              </button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <p v-if="transactions.length === 0" class="empty-transactions">
      {{ $t('transactions.empty') }}
    </p>

    <CommonModal
      :modal-title="$t('product_page.orders.shipping_details')"
      v-if="isShippingDetailsModalOpen"
      size="x-small"
      @close="closeShippingDetails"
    >
      <shippingDetails :order="rowToSeeDetails" />
    </CommonModal>

    <CommonModal
      v-if="confirmDialog"
      size="small"
      :modal-title="$t('product_page.orders.confirm_shipment')"
      @close="closeModal"
    >
      <shipmentConfirm @close="closeModal" :order="currentRow" />
    </CommonModal>

    <CommonModal
      v-if="disputeDialog"
      size="small"
      :modal-title="$t('transactions.dispute_create')"
      @close="closeModal"
    >
      <shipmentDispute @close="closeModal" :order="currentRow" />
    </CommonModal>
  </div>

  <paymentWaiting :transactionId="$route.query.transactionId" @end="resetPaymentWaiting" v-else />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { filter, find } from 'lodash'
import m from 'moment'
import CommonModal from '@/components/commons/modal.vue'
import shipmentDispute from '@/components/modules/dialogs/shipmentDispute.vue'
import shipmentConfirm from '@/components/modules/dialogs/shipmentConfirm.vue'
import shippingDetails from '@/components/modules/dialogs/shippingDetails.vue'
import paymentWaiting from '@/components/modules/payments/paymentWaiting.vue'

export default {
  components: {
    CommonModal,
    shipmentDispute,
    shipmentConfirm,
    shippingDetails,
    paymentWaiting
  },
  data() {
    return {
      confirmDialog: false,
      disputeDialog: false,
      currentRow: null,
      isShippingDetailsModalOpen: false,
      rowToSeeDetails: null,

      transactionWaintingResult: false,
      
      search: ''
    }
  },
  computed: {
    ...mapGetters({
      transactions: 'getTransactions',
    }),
    enabledOrders() {
      return filter(this.getMyOrders, { enabled: 1 })
    },
    soldOrders() {
      return filter(this.getMyOrders, { enabled: 0, sold: 1 })
    },
    cancelOrders() {
      return filter(this.getMyOrders, { enabled: 0, sold: 0 })
    },
    returnFilterTransactions() {
      return filter(this.transactions, (o) => {
        if (o.infos) {          
          return o.infos[0].code && !o.infos[0].label && (o.infos[0].name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ||
            o.infos[0].code.toLowerCase().indexOf(this.search.toLowerCase()) !== - 1 ||
            String(o.infos[0].total_price_gross).indexOf(this.search.toLowerCase()) !== - 1 ||
            String(o.created_at).toLowerCase().indexOf(this.search.toLowerCase()) !== - 1)
        }
      })
    },
  },
  mounted() {
    if (this.$route.query.transactionId) {
      this.transactionWaintingResult = true;
    }

    this.getAllTransactions();
  },
  methods: {
    ...mapActions({
      getAllTransactions: 'getAllTransactions',
      getDownloadInvoice: 'downloadInvoice',
    }),
    downloadInvoice(invoice) {
      this.getDownloadInvoice(invoice)
    },
    dateConvert(date) {
      return m(date).format('DD/MM/YYYY')
    },
    openDisputeShipmentOrder(row) {
      this.currentRow = row
      this.disputeDialog = true
    },
    openConfirmShipment(row) {
      this.currentRow = row
      this.confirmDialog = true
    },
    closeModal() {
      this.disputeDialog = false
      this.confirmDialog = false
    },
    returnName(row) {
      const findName = find(row.infos, (o) => {
        return o.code && o.name
      })

      if (findName) {
        return findName.name
      }
      return 'N/A'
    },
    closeShippingDetails() {
      this.isShippingDetailsModalOpen = false
    },
    showDetailShippingModal(row) {
      this.rowToSeeDetails = {
        shipment: {
          tracking_number: row.ups_tracking,
          ups_package_status: row.ups_package_status
        },
      }
      this.isShippingDetailsModalOpen = true
    },
    resetPaymentWaiting() {
      this.$router.push('/auth/transactions/history');
      this.transactionWaintingResult = false;
      this.getAllTransactions();
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.dashboard {
  margin: 30px;
  margin-top: 20px;
  
  .transactions-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    display: flex;
  }
}

.orders-searchbar {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  
  input {
    box-sizing: border-box;
    width: 240px;
    height: 32px;
    border: none;
    background: #333;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 5px 10px;
    font-size: 15px;
  }

  button {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 32px;
    background: #2667ff;
    border: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 5px 12px;
    font-size: 15px;
  }
}

.transactions {
  border-radius: 12px;
  border: 1px solid $border;
  background: #333;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 50px;
  overflow: hidden;
}

.title,
.price {
  display: flex;
  text-align: start;
  word-break: keep-all;
  color: white;
  font-size: 15px;
  font-weight: 500;
  border: 0;
  background: transparent;
}

.ticker-maitre,
.status {
  border-radius: 6px;
  border: 1px solid $border;
  color: $grey40;
  background: transparent;
  padding: 3px;
}

.status-paid {
  color: #add7f6;
  border: 1px solid #add7f6;
}

.delivery {
  border: none;
  color: $grey40;
  background: transparent;
}

.live-orders {
  justify-content: flex-start;

  .case-buy,
  .case-sell,
  .case-taker,
  .case-giver {
    width: 90px;

    .empty-order {
      margin-left: 10px;
    }
  }

  .case-sell {
    .empty-order {
      margin-left: 22px;
    }
  }

  .case-taker {
    width: 105px;

    .empty-order {
      margin-left: 33px;
    }
  }

  .case-giver {
    width: 105px;
    margin-left: 10px;

    .empty-order {
      margin-left: 33px;
    }
  }
}

.transactions-history-actions {
  display: flex;
  justify-content: left;

  button {
    display: flex;
    align-items: center;
    margin-right: 4px;
  }
}

.live-order-list-buy,
.live-order-list-sell,
.live-order-list-taker,
.live-order-list-giver {
  display: flex;
  border-radius: 50px;
  border: 1px solid $border;
  background: #444;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
  height: 27px;
  width: max-content;
  padding: 2px 10px;
}

.live-order-list-buy,
.live-order-list-sell,
.live-order-list-taker,
.live-order-list-giver {
  justify-content: center;
  align-items: center;
  margin-left: 0;

  img {
    background: #84cc16;
    border-radius: 50%;
  }

  p {
    color: white;
    font-size: 15px;
    font-weight: 500;
    margin-left: 6px;
  }

  span {
    color: $grey40;
    font-size: 15px;
    font-weight: 500;
    margin-left: 2px;
  }
}

.live-order-list-sell {
  margin-left: 0;

  img {
    background: #f43f5e;
  }
}

.live-order-list-taker,
.live-order-list-giver {
  img {
    background: #a855f7;
  }
}

.live-order-list-giver {
  img {
    background: $blue;
  }
}

.report-on {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  animation-name: colorChange;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  cursor: pointer;
}

.empty-transactions {
  margin: 30px;
  margin-top: 0;
  margin-left: 0;
}

@keyframes colorChange {
  0% {
    background-color: #ffa2a2;
  }
  20% {
    background-color: #ff0000;
  }
  100% {
    background-color: #ff0000;
  }
}

.download-invoice {
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  background: transparent;
  box-shadow: 0px;
  color: white;
  font-size: 15px;
  font-weight: 500;
  height: 30px;
  width: 30px;
}

.el-table tr,
.el-table th.el-table__cell {
  background-color: #333333;
  font-size: 15px;
  font-weight: 500;
}

.el-table th.el-table__cell.is-leaf,
.el-table td.el-table__cell {
  border-bottom: 1px solid $border;
}

.el-table thead {
    color: white;
  }

.el-table__body tr:hover > td.el-table__cell {
  background-color: transparent;
}

.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell,
.el-table--striped:hover
  .el-table__body
  tr.el-table__row--striped:hover
  td.el-table__cell {
  background: #ffffff32;
}

.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #444444;
}

.el-table__inner-wrapper::before {
  background-color: transparent;
}

@media screen and (max-width: 500px) {

  .dashboard {
    margin: 5px;
    margin-top: 20px;
  }

  .transactions-options {
    margin-left: 10px;
    margin-top: 0!important;

    h1 {
      margin-bottom: 10px;
      padding-left: 0!important;
    }

    .orders-searchbar {
      margin-left: 0;
    }
  }

  .transactions {
    margin: 5px;
  }
}
</style>
